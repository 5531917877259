import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { addClient, getAllEmployees } from '../../services/api';
import "./NeuKunde.css"
import CustomCheckbox from '../CustomCheckbox';

const ConfirmationModal = ({ show, formData, onClose, onConfirm }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">s
        <h2>Review Your Antrag Submission</h2>
        <div className="modal-body">
          <p><strong>VorName :</strong> {formData.vornName}</p>
          <p><strong>Name:</strong> {formData.nachName}</p>

          <p><strong>Geburtstag:</strong> {formData.geburtstag}</p>
          <p><strong>Mobile Nummer:</strong> {formData.number}</p>
          <p><strong>Sent Date:</strong> {formData.startDate}</p>
          <p><strong>gesendet datum:</strong> {formData.submite_date}</p>
          <p><strong>Firma :</strong> {formData.firma}</p>
          <p><strong>Employee:</strong> {formData.empName}</p>
          <p><strong>Kinder:</strong> {formData.kindNum}</p>
        </div>
        <div className="modal-footer">
          <button className="confirm-btn" onClick={onConfirm}>Confirm</button>
          <button className="cancel-btn" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};


const AddClient = () => {

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true); // Show confirmation modal
  };

  const date = new Date().toISOString().split("T")[0]; // Produces a string in "YYYY-MM-DD" format

  const [formData, setFormData] = useState({
    employee_id: '',
    vornName: '',
    nachName: '',
    number: '',
    geburtstag: '',
    submite_date: date,
    firma: '',
    startDate: '', // New start date field
    empName: '',
    kindNum: 0,
    w_vorname: '',
    w_nachname: '',
    w_birthDate: '',
    w_aufname: false,
    w_start_date: '',
    children: [] // Updated to hold objects for each child

  });
  const [error, setError] = useState('');

  const [showModal, setShowModal] = useState(false); // Modal state
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const [employees, setEmployees] = useState([]);

  const [editingAntragId, setEditingAntragId] = useState(null);
  // Fetch employees list on load
  const resetForm = () => {
    setFormData({
      employee_id: '',
      vornName: '',
      startDate: '', // New start date field
      nachName: '',
      number: '',
      geburtstag: '',
      submite_date: date,
      firma: '',
      empName: '',
      kindNum: 0,
      w_vorname: '',
      w_nachname: '',
      w_birthDate: '',
      w_start_date: '',
      w_aufname: false,
      children: [] // Updated to hold objects for each child
    });
    setEditingAntragId(null); // Clear editing state
  };
  // Fetch all employees
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await getAllEmployees();

        setEmployees(response.data); // Set employees in state
      } catch (err) {
        console.error('Error fetching employees:', err);

      }
    };
    fetchEmployees(); // Call fetch function
  }, []); // Run on mount only


  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };


  const handleKindNumChange = (e) => {
    const kindNum = parseInt(e.target.value, 10);
    setFormData({
      ...formData,
      kindNum,
      children: Array(kindNum).fill({ firstName: '', lastName: '', birthdate: '', isOver15: false, isAufname: false, start_date:'' })
    });
  };

  const handleChildChange = (index, field, value) => {
    const updatedChildren = [...formData.children];
    updatedChildren[index] = {
      ...updatedChildren[index],
      [field]: value
    };
    if (field === 'birthdate') {
      const birthDate = new Date(value); // Birthdate from input
      const currentDate = new Date(); // Current date

      // Calculate the age difference in years
      let age = currentDate.getFullYear() - birthDate.getFullYear() + 1;

      // Check if the current date has passed the birthday for this year
      const hasBirthdayPassedThisYear =
        (currentDate.getMonth() > birthDate.getMonth()) ||
        (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() >= birthDate.getDate());

      // If the current date is before the birthday in the current year, subtract 1 from the age
      if (!hasBirthdayPassedThisYear) {
        age--;
      }

      // Update `isOver15` and `isAufname` based on age
      updatedChildren[index].isOver15 = age > 14;

      if (age <= 14) {
        updatedChildren[index].isAufname = false; // Reset 'aufname' if under 15
      }
    }





    setFormData({ ...formData, children: updatedChildren });
  };

  const handleConfirm = async () => {
    try {
      try { await addClient(formData) }
      catch (error) { console.error('Error adding client:', error) };

      setSuccessMessage('Deine kunde ist added!'); // Set success message
      resetForm(); // Reset form

      // Clear the success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 5000);
    } catch (err) {
      console.error('Error saving kunde:', err);
      setError('Failed to save kunde.'); // Set error state
    }
    setShowModal(false); // Close modal
  };
  const handleEmployeeChange = (e) => {
    const selectedEmployee = employees.find(emp => emp.id == e.target.value);
    setFormData({
      ...formData,
      employee_id: e.target.value,
      empName: selectedEmployee ? selectedEmployee.username.split("@")[0] : ''
    });
    console.log('ddd', selectedEmployee.username.split("@")[0])
  };



  const handleSubmit1 = async (e) => {
    e.preventDefault();
    try { await addClient(formData) }
    catch (error) { console.error('Error adding client:', error) };
  };

  return (
    <div className='famielien-form-container'>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p className="success-message" style={{ color: 'green' }}>{successMessage}</p>} {/* Success message display */}

      {/* Modal for confirmation */}
      <ConfirmationModal
        show={showModal}
        formData={formData}
        onClose={() => setShowModal(false)}
        onConfirm={handleConfirm}
      />
      <div className='famielien-form-container'>
        <form onSubmit={handleSubmit}>
          <div><div className='new-personal'>
            <div>
              <label htmlFor="firma">Firma auswahlen:</label>
              <select
                id="firma"
                name="firma"
                value={formData.firma}
                onChange={handleChange}
                required
              >
                <option value="" disabled>Firma auswahlen</option>

                <option key='Big_id' value='Big Direkt' style={{ color: 'black', backgroundColor: '#f2f2f2' }}>
                  Big Direkt {/* Display employee name */}
                </option>
                <option key='Viactive_id' value='Viactive' style={{ color: 'black', backgroundColor: '#f2f2f2' }}>
                  Viactive {/* Display employee name */}
                </option>

              </select></div>
            <div>
              <label htmlFor="employee_id">Mitarbeiter auswahlen:</label>
              <select
                id="employee_id"
                name="employee_id"
                value={formData.employee_id}
                onChange={handleEmployeeChange}
                required
              >
                <option value="" disabled>Mitarbeiter auswahlen</option>
                {employees.map((employee) => (
                  <option
                    key={employee.id}
                    value={employee.id}
                    style={{ color: 'black', backgroundColor: '#f2f2f2' }}
                  >
                    {employee.username.split("@")[0]}
                  </option>
                ))}
              </select>



            </div>
          </div>
            <div className='new-personal'>
              <div>
                <label>First Name:</label>
                <input type="text" name="vornName" value={formData.vornName} onChange={handleChange} required />
              </div>
              <div>
                <label>Last Name:</label>
                <input type="text" name="nachName" value={formData.nachName} onChange={handleChange} required /></div>

              <div>

                <label>Mobile Number:</label>
                <input type="text" name="number" value={formData.number} onChange={handleChange} /></div>
              <div>
                <label htmlFor="geburtstag">Geburtstag:</label>
                <input
                  type="date"
                  id="geburtstag"
                  name="geburtstag"
                  value={formData.geburtstag}
                  onChange={handleChange}
                  required
                />

              </div>

            </div>
            <div className='new-personal'>
              <div>
                <label>Partner Vorname:</label>
                <input type="text" name="w_vorname" value={formData.w_vorname} onChange={handleChange} />
              </div>
              <div>
                <label>Partner Nachname:</label>
                <input type="text" name="w_nachname" value={formData.w_nachname} onChange={handleChange} /> </div>

              <div>
                <label htmlFor="w_birthDate">Partner Geburtstag:</label>
                <input
                  type="date"
                  id="w_birthDate"
                  name="w_birthDate"
                  value={formData.w_birthDate}
                  onChange={handleChange}

                />
              </div>
              <div>

                <CustomCheckbox
                  label="Ist Aufname?"
                  checked={formData.w_aufname || false}
                  onChange={handleChange}
                  name="w_aufname"
                />
              </div>
              <div>
                <label htmlFor="w_start_date">P-Start Date:</label>
                <input
                  type="date"
                  id="w_start_date"
                  name="w_start_date"
                  value={formData.w_start_date}
                  onChange={handleChange}

                />
              </div>

            </div>
          </div>
          <div className='new-personal'>
            <div>
              <label htmlFor="startDate">Start Date:</label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                required
              /></div>


            <div className='person'>
              <label htmlFor="kindNum">Kinder:</label>
              <select id="kindNum" name="kindNum" value={formData.kindNum} onChange={handleKindNumChange} required>
                <option value="" disabled>Select Number of Kinder</option>
                {[...Array(15).keys()].map(num => (
                  <option key={num} value={num}>{num}</option>
                ))}
              </select>
            </div>  </div>

          {Array.from({ length: formData.kindNum }).map((_, index) => (
            <div key={index}>
              <div className='new-personal'>
                <div>
                  <label>Kind {index + 1} VorName :</label>
                  <input
                    type="text"
                    value={formData.children[index]?.firstName || ''}
                    onChange={(e) => handleChildChange(index, 'firstName', e.target.value)}
                  /></div><div>
                  <label>Kind {index + 1} NachName:</label>
                  <input
                    type="text"
                    value={formData.children[index]?.lastName || ''}
                    onChange={(e) => handleChildChange(index, 'lastName', e.target.value)}
                  /></div>
                  <div>
                  <label>Kind {index + 1} Geburtstag:</label>
                  <input
                    type="date"
                    value={formData.children[index]?.birthdate || ''}
                    onChange={(e) => handleChildChange(index, 'birthdate', e.target.value)}
                  />
                  </div>
                  <div>
                  <label>Kind {index + 1} start Date:</label>
                  <input
                    type="date"
                    value={formData.children[index]?.start_date || ''}
                    onChange={(e) => handleChildChange(index, 'start_date', e.target.value)}
                  />
                  </div>
                
                  <div>
                    <CustomCheckbox
                      label="Ist Aufname?"
                      checked={formData.children[index]?.isAufname || false}
                      onChange={(e) => handleChildChange(index, 'isAufname', e.target.checked)}
                      name="w_aufname"
                    />
                  </div>
                

              </div>
            </div>
          ))}


          <button type="submit">Add Client</button>
        </form>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {successMessage && <p className="success-message" style={{ color: 'green' }}>{successMessage}</p>} {/* Success message display */}</div></div>
  );
};

export default AddClient;
